<div *ngIf="dataType(jsonData) === 'ARRAY'">
  ARRAY
  <div *ngFor="let item of jsonData">
    {{ item | json }}
    <!-- <app-html-from-json [jsonData]="item"> </app-html-from-json> -->
  </div>
</div>

<div *ngIf="dataType(jsonData) === 'OBJECT_ARRAY'">
  OBJECT_ARRAY

  <div *ngFor="let item of objectKeys(jsonData)">
    <app-html-from-json [jsonData]="jsonData[item]"></app-html-from-json>
  </div>
</div>

<div *ngIf="dataType(jsonData) === 'OBJECT'">{{ jsonData }}</div>

<div *ngIf="dataType(jsonData) === 'UNKNOWN'">{{ jsonData }}</div>
