import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService, IAuthentication } from './auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private refreshTokenInProgress: boolean = false;
  private refreshToken$ = new BehaviorSubject<string | null>(null);

  constructor(public authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(this.addTokenHeader(request, this.authService.jwt))
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.processRequestError(error, request, next)
        )
      );
  }

  private processRequestError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.authentication.refreshToken)) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this.authService.doLogoutAndRedirectToLogin();
      }
    }

    if (error instanceof HttpErrorResponse && error.status === 401) {
      return this.refreshToken(request, next);
    }

    if (error instanceof HttpErrorResponse && error.status === 403) {
      this.authService.doLogoutAndRedirectToLogin();
    }

    return throwError(() => error);
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshToken$.next(null);

      const token = this.authService.refreshToken;
      if (token) {
        return this.authService.doRefreshToken(token).pipe(
          switchMap(({ token }: IAuthentication) => {
            this.refreshTokenInProgress = false;
            this.refreshToken$.next(token);
            return next.handle(this.addTokenHeader(request, token));
          })
        );
      }
    }

    return this.refreshToken$.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((_token) => next.handle(this.addTokenHeader(request, _token)))
    );
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    token: string | null
  ): HttpRequest<any> {
    if (
      token &&
      !request.url.includes(environment.authentication.refreshToken)
    ) {
      request = request.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${token}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'XRB-Authorization': `ApiKey ${environment.apiKey}`,
          cv: `0.0.1`,
        },
      });
    }

    return request;
  }
}

export const httpRequestInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpRequestInterceptor,
  multi: true,
};
