import { NgModule } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';
import { matHomeOutline } from '@ng-icons/material-icons/outline';
import {
  matHomeSharp,
  matLogoutSharp,
  matEditSharp,
  matInfoSharp,
  matDeleteSharp,
  matPlusSharp,
  matStopSharp,
  matCheckSharp,
  matArrowUpwardSharp,
  matArrowDownwardSharp,
  matCloseSharp,
  matSearchSharp,
  matGridViewSharp,
  matDataObjectSharp,
  matFlightTakeoffSharp,
  matPersonAddDisabledSharp,
  matGavelSharp,
  matReduceCapacitySharp,
  matAddCircleSharp,
  matRemoveSharp,
  matRemoveCircleSharp,
  matDataSaverOnSharp,
  matSaveSharp,
  matChatSharp,
  matChatBubbleSharp,
  matListSharp,
  matFilterSharp,
  matLockSharp,
  matMoreHorizSharp,
  matMoreVertSharp,
} from '@ng-icons/material-icons/sharp';

@NgModule({
  imports: [
    NgIconsModule.withIcons({
      matHomeOutline,
      matHomeSharp,
      matLogoutSharp,
      matEditSharp,
      matInfoSharp,
      matDeleteSharp,
      matPlusSharp,
      matStopSharp,
      matCheckSharp,
      matArrowUpwardSharp,
      matArrowDownwardSharp,
      matCloseSharp,
      matSearchSharp,
      matGridViewSharp,
      matDataObjectSharp,
      matFlightTakeoffSharp,
      matPersonAddDisabledSharp,
      matGavelSharp,
      matReduceCapacitySharp,
      matAddCircleSharp,
      matRemoveSharp,
      matRemoveCircleSharp,
      matDataSaverOnSharp,
      matSaveSharp,
      matChatSharp,
      matChatBubbleSharp,
      matListSharp,
      matFilterSharp,
      matLockSharp,
      matMoreHorizSharp,
      matMoreVertSharp,
    }),
  ],
  exports: [NgIconsModule],
  providers: [],
})
export class IconsProviderModule {}
