import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customtbldata',
})
export class CustomTBLDataPipe extends DatePipe implements PipeTransform {
  override transform(value: any): any {
    if (this.isIsoDate(value)) {
      return super.transform(value, 'dd-MM-yyyy hh:mm:ss');
    }

    if (this.isBoolean(value)) {
      return value;
    }

    return value;
  }

  isIsoDate(str: string) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && d.toISOString() === str; // valid date
  }

  isBoolean(str: boolean) {
    return typeof str === 'boolean';
  }
}
