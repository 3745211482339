export const environment = {
  production: false,
  apiKey: '37a59818-ee25-4f8a-bf5f-75830489890a',
  secretKey: 'zdMHhKUvjA6eEEv9XMCmxfG87VdckUdAxqTyAZUunEfG6rA2skF6KMGMuje3A2nh',
  initial: {
    loginPath: '/',
    mainPath: '/dashboard',
  },
  cache: {
    isEncrypt: false,
  },
  appConfig: {
    apiCallDelay: 1000,
    routerLinkDelay: 800,
  },
  backendServer: { mainApi: 'https://apis-dev-xrb.monkgames.net' },
  itemKeyString: {
    land: 'LAND',
    building: 'BULD',
    npc: 'NPC',
  },
  resolveData: {
    getUserInfo: 'backend/get-userinfo',
    getCharacterInfo: 'backend/get-characterinfo',
  },
  backendData: {
    getNumOnline: 'backend/getNumOnline',
    getOnlineUsers: 'backend/get-all-online',
    getApiLog: 'backend/apirequest-log-paging',
    getAuditLog: 'backend/audit-log-paging',
    getAuthenLog: 'backend/authen-log-paging',
    getCronLog: 'backend/schedule-log-paging',
    getErrorLog: 'backend/error-log-paging',
    getLoggingLog: 'backend/logging-paging',
    getDailyReport: 'backend/daily-reports',
    searchFacialPart: 'backend/search-facialpart',
    deleteUserAvatar: 'backend/delete-user-avatar',
    reSyncCharacterQuest: 'backend/resync-character-quest',
    getGameSetting: 'backend/game-setting',
    clearTownCached: 'backend/purgecached-town',
    kickPlayer: 'backend/kick-player',
    kickAllPlayer: 'backend/kick-all-player',
    unstruckPlayer: 'backend/unstruck-player',
  },
  authentication: {
    login: 'v1/auth/signin-cms',
    logout: 'v1/auth/signout',
    refreshToken: 'v1/auth/refreshtoken',
    resetPassword: 'v1/auth/resetpass',
  },
  general: {
    searchmsdata: 'backend/searchmsdata',
  },
  userEndpoints: {
    listPaging: 'crud/user-paging',
  },
  characterEndpoints: {
    listPaging: 'crud/character-paging',
  },
  msCategory: {
    crud: 'crud/category',
    listPaging: 'crud/category-paging',
    noMetaverse: 'crud/category-nometeverse',
  },
  areaEndpoints: {
    crud: 'crud/area',
    listPaging: 'crud/area-paging',
    addCampaign: '',
  },
  campaignArea: {
    crud: 'crud/campaign-area',
  },
  msLandType: {
    crud: 'crud/ms-landtype',
    listPaging: 'crud/ms-landtype-paging',
  },
  msLandSize: {
    crud: 'crud/ms-landsize',
    listPaging: 'crud/ms-landsize-paging',
  },
  msLand: {
    crud: 'crud/ms-land',
    listPaging: 'crud/ms-land-paging',
  },
  msBuildingType: {
    crud: 'crud/ms-buildingtype',
    listPaging: 'crud/ms-buildingtype-paging',
  },
  msBuildingSize: {
    crud: 'crud/ms-buildingsize',
    listPaging: 'crud/ms-buildingsize-paging',
  },
  msBuilding: {
    crud: 'crud/ms-building',
    listPaging: 'crud/ms-building-paging',
  },
  msBuildingNPCManager: {
    crud: 'crud/npc-concession-spawnpoints',
  },
  msBuildingDoor: {
    crud: 'crud/ms-building-door',
  },
  buildingRoom: {
    crud: 'crud/building-room',
  },
  msInteriorRedeem: {
    crud: 'crud/ms-interior-redeem',
    listPaging: 'crud/ms-interior-redeem-paging',
  },
  msInteriorRedeemItem: {
    crud: 'crud/ms-interior-redeem-item',
  },
  msBuildingDecoration: {
    crud: 'crud/ms-building-decoration',
    listPaging: 'crud/ms-building-decoration-paging',
  },
  msInteriorSet: {
    crud: 'crud/ms-interior-set',
    listPaging: 'crud/ms-interior-set-paging',
    getInfo: 'crud/ms-interior-set',
  },
  msInteriorSetComponent: {
    crud: 'crud/ms-interior-set-component',
  },
  msNPC: {
    crud: 'crud/ms-npc',
    listPaging: 'crud/ms-npc-paging',
  },
  msNPCType: {
    crud: 'crud/ms-npc-type',
    listPaging: 'crud/ms-npc-type-paging',
  },
  msNPCRoute: {
    crud: 'crud/ms-npc-route',
    listPaging: 'crud/ms-npc-route-paging',
  },
  msNPCSpawn: {
    crud: 'crud/ms-npc-spawnpoint',
    listPaging: 'crud/ms-npc-spawnpoint-paging',
  },
  msFilters: {
    crud: 'crud/ms-filter',
    listPaging: 'crud/ms-filter-paging',
  },
  msNPCDialogs: {
    crudTopic: 'crud/ms-dialog-topic',
    crudTopicFilter: 'crud/ms-topic-filter',
    crudDialog: 'crud/ms-dialog',
    crudDialogFilter: 'crud/ms-dialog-filter',
    crudDialogAction: 'crud/ms-dialog-action',
  },
  msEffect: {
    crud: 'crud/ms-effect',
    listPaging: 'crud/ms-effect-paging',
  },
  msSkill: {
    crud: 'crud/ms-skill',
    listPaging: 'crud/ms-skill-paging',
  },
  msSkillLevel: {
    crud: 'crud/ms-skill-level',
    listPaging: 'crud/ms-skill-level-paging',
  },
  msItem: {
    crud: 'crud/ms-item',
    listPaging: 'crud/ms-item-paging',
  },
  msItemCategory: {
    crud: 'crud/ms-item-category',
    listPaging: 'crud/ms-item-category-paging',
  },
  msItemRarity: {
    crud: 'crud/ms-item-rarity',
    listPaging: 'crud/ms-item-rarity-paging',
  },
  msItemType: {
    crud: 'crud/ms-item-type',
    listPaging: 'crud/ms-item-type-paging',
  },
  msItemElementType: {
    crud: 'crud/ms-item-element-type',
    listPaging: 'crud/ms-item-element-type-paging',
  },
  msItemDamageType: {
    crud: 'crud/ms-item-damage-type',
    listPaging: 'crud/ms-item-damage-type-paging',
  },
  msItemRawMaterial: {
    crud: 'crud/ms-item-rawmaterial',
    listPaging: 'crud/ms-item-rawmaterial-paging',
  },
  msItemSet: {
    crud: 'crud/ms-itemset',
    listPaging: 'crud/ms-itemset-paging',
    pushSetParent: 'crud/ms-itemset-pushparent',
    pullSetParent: 'crud/ms-itemset-pullparent',
  },
  msItemSetComponent: {
    crud: 'crud/ms-itemset-component',
    listPaging: 'crud/ms-itemset-component-paging',
  },
  msItemSkill: {
    crud: 'crud/ms-item-skill',
    listPaging: 'crud/ms-item-skill-paging',
  },
  msAvatar: {
    crud: 'crud/ms-avatar',
    listPaging: 'crud/ms-avatar-paging',
  },
  msAvatarRace: {
    crud: 'crud/ms-avatar-race',
    listPaging: 'crud/ms-avatar-race-paging',
  },
  msAvatarRaceSpawnPoint: {
    crud: 'crud/ms-avatar-race-spawnpoint',
    listPaging: 'crud/ms-avatar-race-spawnpoint-paging',
  },
  msAvatarFacial: {
    crud: 'crud/ms-avatar-facial',
    listPaging: 'crud/ms-avatar-facial-paging',
  },
  msAvatarFacialPart: {
    crud: 'crud/ms-avatar-facial-part',
    listPaging: 'crud/ms-avatar-facial-part-paging',
    getInfo: 'crud/ms-avatar-facial-part',
  },
  msNpcClientEvent: {
    crud: 'crud/ms-npc-client-event',
    listPaging: 'crud/ms-npc-client-event-paging',
  },
  msClientEvent: {
    crud: 'crud/ms-client-event',
  },
  msClientEventCommand: {
    crud: 'crud/ms-client-event-command',
  },
  msNpcClientEventActionType: {
    crud: 'crud/ms-npc-client-event-action-type',
    listPaging: 'crud/ms-npc-client-event-action-type-paging',
  },
  msNpcClientEventCommandType: {
    crud: 'crud/ms-npc-client-event-command-type',
    listPaging: 'crud/ms-npc-client-event-command-type-paging',
  },
  metaverseData: {
    landCrud: 'worldmaps/spawn-land',
    landPaging: 'worldmaps/spawn-land-paging',
    buildingCrud: 'worldmaps/spawn-building',
    buildingPaging: 'worldmaps/spawn-building-paging',
    npcCrud: 'worldmaps/spawn-npc',
    npcPaging: 'worldmaps/spawn-npc-paging',
    buildingDecoratorCrud: 'worldmaps/spawn-decorator',
    buildingSpawnRedeemSet: 'worldmaps/spawn-interior-redeem-set',
    buildingCreateDoors: 'worldmaps/spawn-create-doors',
    estate: 'worldmaps/estate',
    estatePaging: 'worldmaps/estate-paging',
    cluster: 'worldmaps/cluster',
    clusterPaging: 'worldmaps/cluster-paging',
    verse: 'worldmaps/verses',
    versePaging: 'worldmaps/verses-paging',
  },
  versionControl: {
    patchCreate: 'patch-managers/createpatch',
    patchPaging: 'patch-managers/patchlist-paging',
    patchActivate: 'patch-managers/activepatch',
    versionGenerate: 'patch-managers/createmsversion',
    versionPaging: 'patch-managers/version-paging',
    versionActivate: 'patch-managers/activeversion',
    generateLandsaleMap: 'patch-managers/generate-landsale-map',
  },
  apiManagement: {
    crud: 'crud/apikey',
    listPaging: 'crud/apikey-paging',
  },
  questData: {
    msQuestCrud: 'crud/ms-quest',
    msQuestPaging: 'crud/ms-quest-paging',
    msQuestObjectiveCrud: 'crud/ms-quest-objective',
    msEventCondCrud: 'crud/ms-event-condition',
    msEventCondPaging: 'crud/ms-event-condition-paging',
    msQuestRewardCrud: 'crud/ms-quest-reward',
    msMilestone: 'crud/ms-milestone',
  },
  achievement: {
    crud: 'crud/ms-achievement',
    listPaging: 'crud/ms-achievement-paging',
  },
  achievementCategory: {
    crud: 'crud/ms-achievement-category',
    listPaging: 'crud/ms-achievement-category-paging',
  },
  achievementRank: {
    crud: 'crud/ms-achievement-rank',
    listPaging: 'crud/ms-achievement-rank-paging',
  },
  achievementReward: {
    crud: 'crud/ms-achievement-reward',
    listPaging: 'crud/ms-achievement-reward-paging',
  },
  achievementCondition: {
    crud: 'crud/ms-achievement-condition',
    listPaging: 'crud/ms-achievement-condition-paging',
  },
  eventReward: {
    crud: 'crud/ms-event-reward',
  },
  extras: {
    nftProfiles: 'backend/nftprofiles',
  },
  gathering: {
    crud: 'worldmaps/gathering',
    listPaging: 'worldmaps/gathering-paging',
  },
  msGatheringNode: {
    crud: 'crud/ms-gathering-node',
    listPaging: 'crud/ms-gathering-node-paging',
  },
  gatheringFarmlog: {
    crud: 'crud/gathering-farmlog',
    listPaging: 'crud/gathering-farmlog-paging',
  },
  msGatheringNodeType: {
    crud: 'crud/ms-gathering-node-type',
    listPaging: 'crud/ms-gathering-node-type-paging',
  },
  msGatheringNodeRarity: {
    crud: 'crud/ms-gathering-node-rarity',
    listPaging: 'crud/ms-gathering-node-rarity-paging',
  },
  msGatheringNodeDrop: {
    crud: 'crud/ms-gathering-node-drop',
    listPaging: 'crud/ms-gathering-node-drop-paging',
    addDropList: 'crud/ms-gathering-node-drop',
  },
  msGatheringNodeDropList: {
    crud: 'crud/ms-gathering-node-drop-list',
    listPaging: 'crud/ms-gathering-node-drop-list-paging',
  },
  msItemPool: {
    crud: 'crud/ms-itempool',
    listPaging: 'crud/ms-itempool-paging',
  },
  gatheringNodeSpawnPoint: {
    crud: 'crud/gathering-node-spawnpoint',
    listPaging: 'crud/gathering-node-spawnpoint-paging',
  },
  buildingInteriorRarity: {
    crud: 'crud/ms-interior-rarity',
    listPaging: 'crud/ms-interior-rarity-paging',
  },
  buildingInteriorCategory: {
    crud: 'crud/ms-interior-category',
    listPaging: 'crud/ms-interior-category-paging',
  },
  buildingInteriorType: {
    crud: 'crud/ms-interior-type',
    listPaging: 'crud/ms-interior-type-paging',
  },
  msMultisell: {
    crud: 'crud/ms-multisell',
    listPaging: 'crud/ms-multisell-paging',
  },
  msMultisellProduct: {
    crud: 'crud/ms-multisell-product',
  },
  msMultisellIngredient: {
    crud: 'crud/ms-multisell-Ingredient',
  },
  msCraftRecipe: {
    crud: 'crud/ms-craftrecipe',
    listPaging: 'crud/ms-craftrecipe-paging',
  },
  msCraftRecipeIngredient: {
    crud: 'crud/ms-craftrecipe-Ingredient',
  },
  msBillboard: {
    crud: 'ms-billboard',
    listPaging: 'ms-billboard',
  },
  billboard: {
    crud: 'billboard',
    listPaging: 'billboard',
  },
  adsCampaign: {
    crud: 'ads-campaign',
    listPaging: 'ads-campaign',
  },
  adsMedia: {
    crud: 'ads-media',
    listPaging: 'ads-media',
  },
  adsItemCampaign: {
    crud: 'crud/ads-item-campaign',
    listPaging: 'crud/ads-item-campaign',
  },
  userBackend: {
    crud: 'user-backend',
    listPaging: 'user-backend',
  },
  userPolicy: {
    crud: 'user-policy',
    listPaging: 'user-policy',
    area: 'user-policy/area',
  },
  userBrand: {
    crud: 'user-brand',
    listPaging: 'user-brand',
  },
  msReward: {
    crud: 'ms-reward',
    listPaging: 'ms-reward',
  },
};
