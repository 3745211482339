import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseAPIEntity } from './base-api.interface';
import { BaseService } from './base.service';
import { LocalStorageService } from './local-storage.service';

export interface IAuthentication {
  token: string;
  refresh_token: string;
  [key: string]: any;
}

export interface IUserData {
  exp: number;
  iat: number;
  role: number;
  sub: [key: string];
  uuid: string;
  user_id: string;
  username: string;
  user_type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  // Private
  private loginAPI = `${environment.backendServer.mainApi}/${environment.authentication.login}`;
  private refreshTokenAPI = `${environment.backendServer.mainApi}/${environment.authentication.refreshToken}`;
  private logoutAPI = `${environment.backendServer.mainApi}/${environment.authentication.logout}`;
  private resetPasswordAPI = `${environment.backendServer.mainApi}/${environment.authentication.resetPassword}`;
  private getAreaPermission = `${environment.backendServer.mainApi}/${environment.userPolicy.area}`;

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  public areaKeys: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(
    []
  );

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    super();
  }

  public get jwt(): string | null {
    return this.getToken();
  }

  public get refreshToken(): string | null {
    return this.getRefreshToken();
  }

  public login(email: string, password: string): Observable<IAuthentication> {
    return this.http
      .post<BaseAPIEntity<IAuthentication>>(this.loginAPI, {
        email,
        password,
      })
      .pipe(
        switchMap((res) => {
          return this.getAllowArea(res.payload.token).pipe(
            map((areaRes) => {
              return res;
            })
          );
        }),
        tap((res) => {
          return this.doLoginUser(res.payload);
        }),
        map((res) => {
          return res.payload;
        })
      );
  }

  public getAllowArea(token: string): Observable<number[]> {
    return this.http
      .get<BaseAPIEntity<number[]>>(this.getAreaPermission, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: 'Bearer ' + token,
        },
      })
      .pipe(
        tap((res) => {
          this.areaKeys.next(res.payload);
        }),
        map((areaRes) => {
          return areaRes.payload;
        })
      );
  }

  public logout(): Observable<any> {
    return this.http.post(this.logoutAPI, {}).pipe(
      tap(() => this.doLogoutUser()),
      catchError(() => {
        this.doLogoutUser();
        return of(false);
      })
    );
  }

  public doLogoutAndRedirectToLogin(): void {
    this.doLogoutUser();
    this.router.navigate([environment.initial.loginPath]);
  }

  public isLoggedIn$(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user) => !!user),
      catchError(() => of(false))
    );
  }

  public getCurrentUser$(): Observable<IUserData | null> {
    return this.getCurrentUser();
  }

  public doRefreshToken(token: string): Observable<IAuthentication> {
    return this.http
      .post<BaseAPIEntity<IAuthentication>>(
        this.refreshTokenAPI,
        {},
        {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .pipe(
        switchMap((res) => {
          return this.getAllowArea(res.payload.token).pipe(
            map((areaRes) => {
              return res;
            })
          );
        }),
        tap((res) => this.doLoginUser(res.payload)),
        map((res) => res.payload)
      );
  }

  public resetPassword(body: any): Observable<any> {
    return this.http
      .post<BaseAPIEntity<any>>(this.resetPasswordAPI, body, this.options)
      .pipe(catchError(this.handleError));
  }

  private doLoginUser({ token, refresh_token }: IAuthentication): void {
    this.localStorageService.saveDataLocal(this.JWT_TOKEN, token);
    this.localStorageService.saveDataLocal(this.REFRESH_TOKEN, refresh_token);
  }

  private doLogoutUser(): void {
    this.localStorageService.removeKeyLocal(this.JWT_TOKEN);
    this.localStorageService.removeKeyLocal(this.REFRESH_TOKEN);
  }

  private getCurrentUser(): Observable<IUserData | null> {
    const token = this.getToken();
    if (token) {
      const encodedPayload = token.split('.')[1];
      const payload = window.atob(encodedPayload);
      return of(JSON.parse(payload));
    } else {
      return of(null);
    }
  }

  private getToken(): string | null {
    const jwt = this.localStorageService.getDataLocal<string>(this.JWT_TOKEN);
    return jwt;
  }

  private getRefreshToken(): string | null {
    const jwt = this.localStorageService.getDataLocal<string>(
      this.REFRESH_TOKEN
    );
    return jwt;
  }
}
