import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authen',
  templateUrl: './authen.component.html',
  styleUrls: ['./authen.component.scss'],
})
export class AuthenComponent implements OnInit {
  openSideMenu = false;

  menuItem = [
    {
      title: 'Dashboard',
      icon: 'home',
      children: [],
    },
    {
      title: 'Metaverse Data',
      icon: 'database',
      children: [
        {
          title: 'Metaverse Account',
          children: [{ title: 'User List', link: 'user/user-list' }],
        },
        {
          title: 'Metaverse Map',
          children: [
            { title: 'Land', link: 'user/user-list' },
            { title: 'Building', link: '/map/building' },
            { title: 'NPC', link: '/map/npc' },
          ],
        },
      ],
    },
  ];

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}

  onToggleSideMenu(): void {
    this.openSideMenu = !this.openSideMenu;
  }

  /* openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  } */

  onLogout(): void {
    this.authService.logout().subscribe({
      next: () => {
        setTimeout(() => {
          this.router.navigate([environment.initial.loginPath]).then(() => {});
        }, 500);
      },
      error: () => {
        setTimeout(() => {
          this.router.navigate([environment.initial.loginPath]).then(() => {});
        }, 500);
      },
    });
  }
}
