import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class BaseService {
  protected options = { observe: 'response' as const };
  constructor() {}
  protected handleError(error: HttpErrorResponse): any {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }

    return throwError(() => {
      return 'Something bad happened; please try again later.';
    });
  }
}
