import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface PS {
  edit: boolean;
}

export interface FlexData {
  static: boolean;
  label: string;
  value: any;
  type: string;
  fieldName: string;
  layout: string;
  ctrlPlaceholder: string;
  optionSelector: any;
}

@Component({
  selector: 'app-flex-between',
  templateUrl: './flex-between.component.html',
  styleUrls: ['./flex-between.component.scss'],
})
export class FlexBetweenComponent implements OnInit {
  @Input() data: FlexData[];
  @Input() enableEdit: boolean = false;
  @Output() dataFieldUpdate = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onDataChange(modifiedData: any) {
    if (modifiedData.type === 'number') {
      modifiedData.value = Number(modifiedData.value);
    }
    this.dataFieldUpdate.emit(modifiedData);
  }
}
