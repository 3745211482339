import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-html-from-json',
  templateUrl: './html-from-json.component.html',
  styleUrls: ['./html-from-json.component.scss'],
})
export class HtmlFromJsonComponent implements OnInit {
  @Input() jsonData: any;
  objectKeys = Object.keys;

  constructor() {}

  ngOnInit(): void {}

  dataType(value: any) {
    //
    if (Array.isArray(value)) {
      return 'ARRAY';
    }

    if (typeof value === 'object') {
      if (this.objectKeys(value).length > 1) {
        return 'OBJECT_ARRAY';
      }
      return 'OBJECT';
    }

    return 'UNKNOWN';
  }
}
