import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
import * as CtyUTF8 from 'crypto-js/enc-utf8.js';
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private passphrase = environment.secretKey;
  private isEncrypt = environment.cache.isEncrypt || false;

  constructor() {
    if (!this.passphrase) {
      throw new Error('Please Provide `secretKey` in environment');
    }
  }

  /**
   * Saves data local
   *
   * @param keyName string
   * @param value any
   * @param exp minutes
   */
  public saveDataLocal(keyName: string, value: any, exp: number = 0): void {
    const obj = {
      data: value,
      exp: exp ? this.getDate().add(exp, 'minutes').unix() : null,
    };

    const encryptedValue = this.isEncrypt
      ? this.encrypt(JSON.stringify(obj))
      : JSON.stringify(obj);
    localStorage.setItem(
      `${keyName.split(' ').join('+')}::${window.location.origin}`,
      encryptedValue
    );
  }

  public getDataLocal<T>(keyName: string): T | null {
    try {
      const res = localStorage.getItem(
        `${keyName.split(' ').join('+')}::${window.location.origin}`
      );

      // Check Have a value
      if (res === null) {
        return null;
      }

      const obj = this.isEncrypt ? this.decrypt(res) : this.parseObject(res);

      // Check Cache Expire
      const now: number = this.getDate().unix();
      if (obj.exp !== null && now > obj.exp) {
        return null;
      }

      return obj.data;
    } catch {
      return null;
    }
  }

  public removeKeyLocal(keyName: string): void {
    localStorage.removeItem(
      `${keyName.split(' ').join('+')}::${window.location.origin}`
    );
  }

  public removeAllLocal(): void {
    localStorage.clear();
  }

  // Session
  public saveDataSession(keyName: string, value: any, exp: number = 0): void {
    const obj = {
      data: value,
      exp: exp ? this.getDate().add(exp, 'minutes').unix() : null,
    };

    const encryptedValue = this.isEncrypt
      ? this.encrypt(JSON.stringify(obj))
      : JSON.stringify(obj);
    sessionStorage.setItem(
      `${keyName.split(' ').join('+')}::${window.location.origin}`,
      encryptedValue
    );
  }

  public getDataSession(keyName: string): any {
    try {
      const res = sessionStorage.getItem(
        `${keyName.split(' ').join('+')}::${window.location.origin}`
      );

      // Check Have a value
      if (res === null) {
        return null;
      }

      const obj = this.isEncrypt ? this.decrypt(res) : this.parseObject(res);

      // Check Cache Expire
      const now: number = this.getDate().unix();
      if (obj.exp !== null && now > obj.exp) {
        return null;
      }

      return obj.data;
    } catch {
      return null;
    }
  }

  public removeKeySession(keyName: string): void {
    sessionStorage.removeItem(
      `${keyName.split(' ').join('+')}::${window.location.origin}`
    );
  }

  public removeAllSession(): void {
    sessionStorage.clear();
  }

  public getDate(): any {
    return moment();
  }

  private encrypt(data: string): string {
    return crypto.AES.encrypt(data, this.passphrase).toString();
  }

  private decrypt(data: string): any {
    const bytesData = crypto.AES.decrypt(data, this.passphrase);
    try {
      return JSON.parse(bytesData.toString(CtyUTF8));
    } catch {
      return bytesData.toString(CtyUTF8);
    }
  }

  private parseObject(data: any): any {
    try {
      return JSON.parse(data);
    } catch {
      return data;
    }
  }
}
