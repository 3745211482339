<header class="d-flex">
  <!-- Left Section -->
  <div class="text-site-name">XRB-Multiverse</div>

  <!-- Right Section -->
  <div class="header-right-panel">
    <nz-avatar
      style="background-color: #87d068"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menu"
    >
    </nz-avatar>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="onLogout()">
          <ng-icon
            name="matLogoutSharp"
            size="15px"
            class="ng-icon-alignment me-2"
          ></ng-icon>
          Logout
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</header>

<div class="mobile-nav-body" [ngClass]="{ 'active fadeIn': openSideMenu }">
  <div
    class="mobile-nav-wrapper main-menu-wrap"
    [ngClass]="{ 'active fadeIn': openSideMenu }"
  >
    <div class="close_bar pointer" (click)="onToggleSideMenu()">
      <ng-icon
        name="matCloseSharp"
        size="15px"
        class="ng-icon-alignment me-2"
      ></ng-icon>
    </div>

    <div class="mobile-sidebar">
      <div class="top-path">
        <ul>
          <li>
            <a routerLink="/dashboard" class="nav-link"
              ><span class="title-link">Dashboard</span></a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="dashboard openFullBar padd">
  <div class="sidebar dash-side d-none d-lg-flex">
    <div class="top-path">
      <ul nz-menu nzMode="vertical" style="width: 200px">
        <li
          nzMatchRouter
          nzMatchRouterExact
          routerLink="/dashboard"
          nz-menu-item
        >
          Dashboard
        </li>

        <li nz-submenu nzTitle="Metaverse Data">
          <ul>
            <li nz-menu-group nzTitle="Metaverse Account">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="user/user-list"
                >
                  User List
                </li>
              </ul>
            </li>
            <li nz-menu-group nzTitle="Metaverse Map">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/map/land"
                >
                  Land
                </li>
              </ul>
            </li>
            <li nz-menu-group nzTitle="Metaverse Item">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/meta-item/avatars"
                >
                  Avatar
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/meta-item/items"
                >
                  Item
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Master Data">
          <ul>
            <li nz-menu-group nzTitle="Cores">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-category"
                >
                  Category
                </li>
              </ul>
            </li>
            <li nz-menu-group nzTitle="Map Element Data">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-area"
                >
                  MS-Area
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-land"
                >
                  MS-Land
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-land-type"
                >
                  MS-Land Type
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-land-size"
                >
                  MS-Land Size
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-building"
                >
                  MS-Building
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-building-type"
                >
                  MS-Building Type
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-building-size"
                >
                  MS-Building Size
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-building-decoration"
                >
                  MS-Building Decoration
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-npc"
                >
                  MS-Npc
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-billboard"
                >
                  MS-Billboard
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-reward"
                >
                  MS-Reward
                </li>
              </ul>
            </li>

            <li nz-menu-group nzTitle="Item Data">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-item"
                >
                  MS Item
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-itemset"
                >
                  MS ItemSet
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-avatar"
                >
                  MS Avatar
                </li>
              </ul>
            </li>

            <li nz-menu-group nzTitle="Effect Data">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/ms/ms-effect"
                >
                  Effect
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Advertising">
          <ul>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/ms/billboard"
            >
              Billboards
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/ms/ads-campaign"
            >
              Ads Campaigns
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/ms/ads-media"
            >
              Ads Media
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Game Configulation">
          <ul>
            <li nz-menu-group nzTitle="Game Restriction">
              <ul>
                <li
                  nz-menu-item
                  nzMatchRouter
                  nzMatchRouterExact
                  routerLink="/"
                >
                  Forbidden character name
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Patch Manager">
          <ul>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/vc/ms-version-data"
            >
              Patch Data
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/vc/ms-version"
            >
              Version Control
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="API Management">
          <ul>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/apimgmt/api-key"
            >
              Apis Key
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="System Log">
          <ul>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/syslog/apis-log"
            >
              Apis Logs
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/syslog/logging-log"
            >
              Logging
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/syslog/authen-log"
            >
              Authen Logs
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/syslog/audit-log"
            >
              Audit Logs
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/syslog/cron-log"
            >
              Cron Logs
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/syslog/error-log"
            >
              Error Logs
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/syslog/reports"
            >
              Reports
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Authentication">
          <ul>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/ms/user-backend"
            >
              Backend User
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/ms/user-policy"
            >
              User Policy
            </li>
            <li
              nz-menu-item
              nzMatchRouter
              nzMatchRouterExact
              routerLink="/ms/user-brand"
            >
              All User Brand
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
