/* import { IconsProviderModule } from './icons-provider.module'; */
import { CustomTBLDataPipe } from './pipes/custom-tbl-data.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CustomTimePipe } from './pipes/customtime.pipe';
import { PrettyJsonPipe } from './pipes/prettyJson.pipe';
import { YesNoPipe } from './pipes/yesno.pipe';
import { FlexBetweenComponent } from './components/flex-between/flex-between.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { HtmlFromJsonComponent } from './components/html-from-json/html-from-json.component';
import { AngJsoneditorModule } from '@maaxgr/ang-jsoneditor';
import { IconsProviderModule } from './icons-provider.module';
import { NzIconModule } from 'ng-zorro-antd/icon';

const nzModuleList = [
  IconsProviderModule,
  NzAvatarModule,
  NzCardModule,
  NzDropDownModule,
  NzGridModule,
  NzLayoutModule,
  NzMenuModule,
  NzFormModule,
  NzMessageModule,
  NzInputModule,
  NzModalModule,
  NzProgressModule,
  NzRadioModule,
  NzTagModule,
  NzToolTipModule,
  NzButtonModule,
  NzSelectModule,
  NzEmptyModule,
  NzPopconfirmModule,
  NzSwitchModule,
  NzCheckboxModule,
  NzStatisticModule,
  NzBreadCrumbModule,
  NzTableModule,
  NzDividerModule,
  NzStepsModule,
  NzTabsModule,
  NzListModule,
  NzBadgeModule,
  NzAlertModule,
  NzDatePickerModule,
  NzAffixModule,
  NzAnchorModule,
  NzAutocompleteModule,
  NzBackTopModule,
  NzCalendarModule,
  NzCarouselModule,
  NzCascaderModule,
  NzCollapseModule,
  NzCommentModule,
  NzDescriptionsModule,
  NzDrawerModule,
  NzI18nModule,
  NzInputNumberModule,
  NzMentionModule,
  NzNotificationModule,
  NzPageHeaderModule,
  NzPaginationModule,
  NzPopoverModule,
  NzRateModule,
  NzResultModule,
  NzSkeletonModule,
  NzSliderModule,
  NzSpinModule,
  NzTimelineModule,
  NzTimePickerModule,
  NzTransferModule,
  NzTreeModule,
  NzTreeSelectModule,
  NzTypographyModule,
  NzUploadModule,
  NzIconModule,
];

@NgModule({
  declarations: [
    CustomTimePipe,
    CustomTBLDataPipe,
    PrettyJsonPipe,
    YesNoPipe,
    TruncatePipe,
    FlexBetweenComponent,
    HtmlFromJsonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    nzModuleList,
    AngJsoneditorModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    nzModuleList,
    CustomTimePipe,
    PrettyJsonPipe,
    YesNoPipe,
    TruncatePipe,
    CustomTBLDataPipe,
    FlexBetweenComponent,
    HtmlFromJsonComponent,
    AngJsoneditorModule,
  ],
})
export class SharedModule {}
