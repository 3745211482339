import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public/public.component';
import { AuthenComponent } from './authen/authen.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [PublicComponent, AuthenComponent],
  imports: [CommonModule, RouterModule, SharedModule],
})
export class LayoutModule {}
